import {defineStore} from "pinia";
import {ref} from 'vue'
import {debounce} from "lodash";

export const usePeopleStore = defineStore('people', () => {
    const people = ref([]);
    const nextId = ref(1);

    const newlyAddedPeople = ref([]);

    const currentSelectedPeople = ref([]);

    const reset = () => {
        console.debug("RESET PEOPLE STORE");
        newlyAddedPeople.value = [];
        currentSelectedPeople.value = [];
        search();
    }
    const search = debounce(async (query) => {
        let response = await axios.post(route('api.people.search'), {
            search: query
        })
        people.value = response.data.data
            .concat(currentSelectedPeople.value.filter(x => response.data.data.findIndex(v => v.name === x.name) === -1)) // add current selected when not in people
            .concat(newlyAddedPeople.value.filter(x => response.data.data.findIndex(v => v.name === x.name) === -1)); // add newly added and not yet saved

        if (!query)
            nextId.value = Math.max(...people.value.map(o => o.id), 1) + 1;
    }, 500)

    // Run search on boot
    search()

    return {nextId, people, search, newlyAddedPeople, reset, currentSelectedPeople}
})